<template>
  <div class="page_bg company_list_bg">
    <div class="classify_top flex-r-s-c">
      <van-search ref="searchVal" v-model="serValue" class="classify_ser" placeholder="请输入公司名称查询" shape="round"
                  @search="inputSer" @focus="onFocus" @input="inputWord"
      />
    </div>
    <div class="coupon_box_copy">
      <div class="flex-c-s-s">
        <div v-for="(item1,index1) in companyList" :key="index1" style="position: relative;"
             @click="backCompany(item1)"
        >
          <div class="company_list_box">
            {{ item1.companyName }}
          </div>
          <van-divider :style="{ padding: '0 0.5rem'}" />
        </div>
      </div>
      <van-empty v-if="showEmpty" class="custom-image"
                 image="https://oss.xiaoyi120.com/shop2.0/home/serpro_empty.png"
                 description="没有查询到可用公司"
      />
    </div>
  </div>
</template>

<script>
import './index.scss'
import { wildcard } from '@/api/public'
export default {
  data() {
    return {
      showEmpty: false,
      companyList: [],
      serValue: ''

    }
  },
  mounted() { this.$nextTick(() => { this.$refs.searchVal.querySelector('input').focus() }) },
  created() {
  },
  methods: {
    backPath() { window.history.back() },
    inputWord(e) {
      console.log(e)
      wildcard({ companyName: e }).then(res => {
        if (res.data) {
          this.companyList = res.data
          this.showEmpty = false
        } else {
          this.showEmpty = true
        }
      })
    },

    inputSer() {
    },
    onFocus() { },

    backCompany(item) {
      sessionStorage.setItem('nameInvoice', item.companyName)
      window.history.back()
    }
  }
}
</script>
 <style lang="scss">
.classify_top {
  position: relative;
  width: 10rem;
  height: 1.6rem;
  background: #ffffff;
  .classify_ser {
    width: 100%;
    .van-search__content {
      width: 100%;
      height: 0.85rem;
      background: #ffffff;
      border: 1px solid #0767ab;
      opacity: 1;
      border-radius: 0.48rem;
    }
  }
  .classify_btn {
    position: absolute;
    right: 2.4rem;
    top: 0rem;
    .van-button {
      height: 0.69rem;
    }
  }
}
.company_list_box {
  width: 9rem;
  font-size: 0.43rem;
  text-align: left;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.company_list_bg .coupon_box_copy {
  background: #ffffff;
  // background: #f3a4a4;
  padding-top: 0.2rem;
}
</style>
